<template>
  <form id="frm_action">
    <div class="p-formgrid">
      <Toolbar class="fixed-bottom-toolbar div-button css-button-top-right">
        <template slot="right">
          <Button
              label="Back"
              @click="backToList()"
              id="back"
              class="main-style-button"
          />
        </template>
      </Toolbar>
      <div class="col-6">
        <div class="p-card-field">
          <label class="p-col-fixed txt-right txt-required">Name</label>
          <div class="p-col">
            <InputText id="name" type="text" class="" v-model="obj_data.name" placeholder="Input" />
          </div>
        </div>
        <div class="p-card-field">
          <label class="p-col-fixed txt-right txt-required">Category</label>
          <div class="p-col">
            <v-select :key="key_type_id" :clearable="false" title="Property" class="" @search="SearchPropertyType"
              placeholder="Choose" v-model="obj_data.category_id" :options="listPropertyType" :reduce="item => item.id"
              label="name"><!-- -->
              <span slot="no-options" @click="$refs.select.open = false">
                Empty data
              </span>
            </v-select>
            <input class="input_tmp_validator" id="type_id">
          </div>
        </div>
        <div class="p-card-field">
          <label class="p-col-fixed txt-right txt-required">Address</label>
          <div class="p-col">
            <vue-google-autocomplete id="address" class="p-inputtext p-col-width pac-target-input" placeholder="Input"
              v-model="obj_data.address" @placechanged="handlePlaceChanged" />
          </div>
        </div>
        <div class="p-card-field style_box_lat_log">
          <div class="box_lat_log">
            <label class="p-col-fixed txt-right txt-required">Latitude</label>
            <div class="p-col">

              <InputText id="latitude" v-model="obj_data.latitude" placeholder="Input" />
            </div>
          </div>
          <div class="box_lat_log">
            <label class="p-col-fixed txt-right txt-required">Longtitude</label>
            <div class="p-col">
              <InputText id="longtitude" v-model="obj_data.longtitude" placeholder="Input" />
            </div>
          </div>
        </div>
      </div>



      <div class="col-12">
        <div class="p-card-field" v-for="(item_ll, index_ll) in listLang" :key="index_ll">
          <label class="p-col-fixed txt-right txt-required">Description
            ({{ item_ll.iso_code.toString().toUpperCase() }})</label>
          <div class="p-col">
            <input class="input_tmp_validator" :id="'validator_content_' + item_ll.iso_code">
            <CkditorCore :contentSet="item_ll.content" @newdata="handleDataCkditor($event, index_ll, 'content')">
            </CkditorCore>
          </div>
        </div>
      </div>


      <div class="col-6">
        <div class="p-card-field">
          <label class="p-col-fixed txt-right txt-required">Images ({{ list_file.length }}/10)</label>
          <div class="p-col">
            <div class="cus-text opacity-text-file">Recommend 500x500px - Maximum 2 MB</div>
            <div class="cus-text opacity-text-file">Accepted file: JPG, JPEG, PNG, GIF.</div>
            <input class="p-inputtext p-component" accept="image/*" name="images[]" id="images_service" type="file"
              @change="onFileSelectFile" multiple /><!--@change="onFileSelectLogo"-->
            <input class="input_tmp_validator" id="list_file">
            <div class="cus-text style_list_image">
              <div class="box-image" v-for="(item, index) in list_file" :key="index">
                <img class="style_img" :src="item.objectURL" />
                <div class="button-clear" @click="onFileClear(item, index)"><img
                    src="/assets/images/icon/cancel_black_24dp.png" /></div>
              </div>
            </div>

          </div>
        </div>
      </div>
      <div class="col-6">

        <div class="p-card-field style_box_lat_log">
          <div class="box_lat_log">
            <label class="p-col-fixed txt-right">Discount</label>
            <div class="p-card-field">
              <div class="p-col">
                <select v-model="obj_data.discount" class="custom-select">
                  <option value="%">%</option>
                  <option value="€">€</option>
                </select>
              </div>
            </div>
          </div>
          <div class="box_lat_log">
            <label class="p-col-fixed txt-right"></label>
            <div class="p-col">
              <InputNumber id="number_percent" class="" v-model="obj_data.discount_value" mode="decimal"
                :minFractionDigits="2" placeholder="Input" @input="validateData" />

            </div>
          </div>
        </div>



        <div class="p-card-field">
          <div class="row">
            <div class="col-6 calendar-css-margin1">
              <label class="p-col-fixed txt-right">Discount from</label>
              <div class="p-col">
                <Calendar  :key="available_from_key" :showOnFocus="false" dateFormat="MM dd, yy" :showIcon="true" appendTo="self" :showClear="true"
                  :manualInput="false" v-model="obj_data.discount_from" placeholder="Choose" />
                <input class="input_tmp_validator" id="available_from" />
              </div>
            </div>

            <div class="col-6 calendar-css-margin2">
              <label class="p-col-fixed txt-right">To</label>
              <div class="p-col">
                <Calendar :showOnFocus="false" dateFormat="MM dd, yy" id="end_booking_date" :showIcon="true"
                  appendTo="self" :showClear="true" :manualInput="false" v-model="obj_data.discount_to"
                  placeholder="Choose" />
                <input class="input_tmp_validator" id="available_to" />
              </div>
            </div>
          </div>
        </div>

        <div class="p-card-field div_check">
          <div class="p-col checkbox">
            <Checkbox v-model="obj_data.active" :binary="true" />
            <label class="p-col-fixed txt-right text">Active</label>
          </div>
        </div>

      </div>
      <Toolbar class="fixed-bottom-toolbar div-button">
        <template slot="right">
          <Button :label="$constants.TEXT_BUTTON.BACK" @click="backToList()" class="p-button-outlined" id="back" />
          <Button v-if="mode == 'edit'" :label="$constants.TEXT_BUTTON.EDIT" @click="saveData()"
            class="main-style-button" />
          <Button v-else :label="$constants.TEXT_BUTTON.ADD" @click="saveData()" class="main-style-button" />
        </template>
      </Toolbar>
      <Dialog header="Delete" :visible.sync="showDeleteDialog" :contentStyle="{ overflow: 'visible' }" :modal="true">
        Are you sure you want to delete?
        <template #footer>
          <Button label="Delete" @click="deleteData()" icon="pi pi-check"
            class="p-button-danger  btn-confirm-delete-dialog" />
          <Button label="Cancel" @click="showDeleteDialog = false" icon="pi pi-times" class="p-button-warning" />
        </template>
      </Dialog>
    </div>
  </form>
</template>
<script>
import moment from "moment-timezone";
import DataServices from '@/core/DataServices'
import CkditorCore from '../CkditorCore';
export default {
  components: {
    CkditorCore,
  },
  props: {
    mode: String,
    model: Object,
    users: Array
  },
  data() {
    return {
      available_from_key:1,

      key_type_id: 1,

      dataModelMadatory: null,
      componentKeyMadatory: 1,
      listDataMadatory: [],

      deleteId: null,
      deleteName: null,
      showDeleteDialog: false,

      mapServicePrice: {},
      dataModelType: null,
      componentKeyType: 1,
      listDataType: [],

      cke_key: 1,
      listLang: [],
      listRecommendationLang: [],
      content_en: '',
      obj_data: {
        discount: '%',
        address: '',
        discount_value: null,


      },
      comFilename: 1,
      list_phone_code: [
        {
          prefix_phone: "+84",
        },
        {
          prefix_phone: "+45",
        }
      ],
      dataBookingRound: [],
      selProperty: null,
      mapPropertyType: {},
      listPropertyType: [],
      listPropertyTypeAll: [],
      listDocsCate: [],
      listDocsCateAll: [],
      listCountry: [],
      listCountryAll: [],
      listState: [],
      listStateAll: [],
      listUser: [],
      listUserAll: [],
      from_to: null,
      address: null,
      start_booking_date: null,
      end_booking_date: null,
      partArr: [],
      time_zone_local: moment.tz.guess(true),
      componentKey: 0,
      list_file: [],
      list_id_file_delete: [],
      maxFileSize: 2097152 * 50, //100MB  2MB   /*104857600, // 100MB*/
      list_part: [
        {
          "uid": null,
          //"uid": "ee053528-31bb-4822-a620-c5664ecd1cd7",
          "part": "1",
          "order_booking": 1,
          "color": null
        },
        {
          "uid": null,
          //"uid": "79ed9b2a-ebfb-46d7-87d4-982e8cdb8f65",
          "part": "2",
          "order_booking": 2,
          "color": null
        },
        {
          "uid": null,
          //"uid": "321674aa-9161-4193-8422-5bd26a3224c2",
          "part": "3",
          "order_booking": 3,
          "color": null
        },
        {
          "uid": null,
          //"uid":  "75629674-e721-493c-81b0-8ba014ae2aa0",
          "part": "4",
          "order_booking": 4,
          "color": null
        },
        {
          "uid": null,
          //"uid": "d9d3a852-1932-43b6-a2b8-b3ac0e7257cf",
          "part": "5",
          "order_booking": 5,
          "color": null
        }
      ],
      list_madatory: [
        {
          "type_of_use_name": 'Property owner',
          "type_of_use": 1,
          "auto_add_to_cart": false,
          "user_remove_it": false,
          "day_of_stay_more_than": 0,
          "index": 0,
        },
        {
          "type_of_use_name": 'Five2one owner',
          "type_of_use": 2,
          "auto_add_to_cart": false,
          "user_remove_it": false,
          "day_of_stay_more_than": 0,
          "index": 1,
        },
        {
          "type_of_use_name": 'Borrower',
          "type_of_use": 3,
          "auto_add_to_cart": false,
          "user_remove_it": false,
          "day_of_stay_more_than": 0,
          "index": 2,
        },
        {
          "type_of_use_name": 'External renter',
          "type_of_use": 4,
          "auto_add_to_cart": false,
          "user_remove_it": false,
          "day_of_stay_more_than": 0,
          "index": 3,
        }
      ],
      index_edit: null,
    }
  },
  watch: {
    'obj_data.discount_value': function (newValue) {
      if (this.obj_data.discount === '%' && newValue > 100) {
        this.obj_data.discount_value = 100;
      }
    }
  },
  async mounted() {
    this.getLisRecommendationCategory();
    var dataLang = await this.$CoreService.getListLang({});
    var list_lang = dataLang.list;
    if (this.model !== undefined && Object.keys(this.model).length > 0) {
      this.obj_data = this.$commonFuction.convertJsonObject(this.model);
      if (this.obj_data.lang_arr) {
        this.obj_data.name = this.obj_data.lang_arr[0].name;
      }
      if (!this.$commonFuction.isEmpty(this.model?.list_file_edit)) {
        var list_file_edit = this.model?.list_file_edit
        for (let i = 0; i < list_file_edit.length; i++) {
          var item_file = list_file_edit[i]
          var obj = { id: item_file.id, 'name': '', 'file': '', 'objectURL': item_file.url, type: 'old_edit' }//type:'old_edit' nếu gặp type này ko update avatar
          this.list_file.push(obj)
        }
      }
      var list_part = this.model?.part ?? []
      for (let g = 0; g < list_part.length; g++) {
        var item_part = list_part[g];
        var order_booking = item_part?.order_booking - 1
        var uid_part = item_part?.uid
        this.list_part[order_booking]["uid"] = uid_part;
      }
      if (!this.$commonFuction.isEmpty(this.obj_data.discount_from)) {
        this.obj_data.discount_from = moment.tz(this.obj_data.discount_from, this.time_zone_local).format("LL");
      }
      if (!this.$commonFuction.isEmpty(this.obj_data.discount_to)) {
        this.obj_data.discount_to = moment.tz(this.obj_data.discount_to, this.time_zone_local).format("LL");
      }
      var lang_ser_arr = this.model?.lang_arr ?? [];
      console.log("lang_ser_arrlength", lang_ser_arr);
      var mapLS = {};
      for (let h = 0; h < lang_ser_arr.length; h++) {
        var itemLS = lang_ser_arr[h];
        console.log("itemLSitemLS", itemLS)
        mapLS[itemLS.id_lang] = itemLS;
      }
      console.log("MAPLS", mapLS)
      for (let i = 0; i < list_lang.length; i++) {
        var itemL = list_lang[i];
        var id_l = itemL.id
        list_lang[i].content = (!this.$commonFuction.isEmptyObject(mapLS[id_l])) ? mapLS[id_l].description : ''
      }
      this.listLang = list_lang
      console.log('listLanglistLanglistLang', JSON.stringify(this.listLang, null, 2));
      //xử lý type
      var type_of_property_arr = this.model?.type_of_property_arr ?? [];
      var list_type_convert = [];
      for (let g = 0; g < type_of_property_arr.length; g++) {
        var itemType = type_of_property_arr[g];
        var itemTypeConvert = {
          "id": itemType?.type_id ?? null,
          "type_name": itemType?.obj_property_type?.name ?? '',
          "price_key": itemType?.price ?? null,
        }
        list_type_convert.push(itemTypeConvert)
      }
      this.listDataType = list_type_convert;
      this.loadTableType();

      //xứ lý mandatory
      var mandatory_arr = this.model?.mandatory_arr ?? [];
      var mapMan = {};
      for (let k = 0; k < mandatory_arr.length; k++) {
        var itemMan = mandatory_arr[k];
        mapMan[itemMan.type_user] = itemMan;
      }
      console.log("mapManmapMan:", mapMan)
      console.log("mandatory_arr:", mandatory_arr)
      for (let m = 0; m < this.list_madatory.length; m++) {
        var itemM = this.list_madatory[m];
        var type_of_use = itemM.type_of_use
        //console.log("type_of_use:",type_of_use)
        //console.log("type_of_use:",type_of_use)
        this.list_madatory[m].auto_add_to_cart = (!this.$commonFuction.isEmptyObject(mapMan[type_of_use])) ? mapMan[type_of_use].auto_add_to_cart : false;
        this.list_madatory[m].user_remove_it = (!this.$commonFuction.isEmptyObject(mapMan[type_of_use])) ? mapMan[type_of_use].user_remove_it : false;
        this.list_madatory[m].day_of_stay_more_than = (!this.$commonFuction.isEmptyObject(mapMan[type_of_use])) ? mapMan[type_of_use].day_of_stay_more_than : false;
      }



      this.dataModelMadatory = DataServices.getModel('service_madatory');
      this.listDataMadatory = this.list_madatory
      console.log("this.listDataMadatory:", this.listDataMadatory)
      this.dataModelMadatory.processData(this.list_madatory)
      this.available_from_key++;
      this.componentKeyMadatory++;


      this.componentKey++;
    } else {
      for (let i = 0; i < list_lang.length; i++) {
        list_lang[i].content = '';
      }
      this.listLang = list_lang
      console.log("listLanglistLanglength:", this.listLang.length)
      //thêm mới
      this.obj_data = {
        /*name:'service for car tax',
        before_arrival_date:1,
        available_from:moment.tz().format("LL"),
        available_to:moment.tz().format("LL"),*/
        active: true,
        type_of_price: '1',
        ordinal: 1,
        discount: '%',
        address: '',
        latitude: '',  // Có thể thêm giá trị mặc định cho latitude
        longtitude: ''  // Có thể thêm giá trị mặc định cho longtitude
      };
      //gán data
      //this.listDataType = [ { "id": "3ba3f00e-d926-437a-bde6-0ca41fcdcb2c", "type_name": "type2", "price_key": 2342, "stt": 1, "index": 0, "type_name_key": "type2", "price_key_tmp": 2342 } ];
      //this.loadTableType()

      this.dataModelMadatory = DataServices.getModel('service_madatory');
      this.listDataMadatory = this.list_madatory
      this.dataModelMadatory.processData(this.list_madatory)
      this.componentKeyMadatory++;

      this.dataModelType = DataServices.getModel('service_property_type');
      console.log('this.dataModelType:', this.dataModelType)
      this.componentKeyType++;

      this.componentKey++;
    }
    //console.log('gia tri cua id router la' + this.$router.$params.id);

  },

  methods: {
    DataOnChangeMadatory(data, f) {
      console.log("f.keyf.key:", f.key)
      console.log("datadatadatadata:", data)
      var index = data.index;
      this.listDataMadatory[index] = data;
      /*if(f.key == 'active'){

      }*/
    },
    handleInput(event) {
      const fieldsToValidate = [
        { id: "number_percent", value: this.obj_data.discount_value },
      ];
      var field = fieldsToValidate[0];
      let inputElement = document.getElementById(field.id);
      const value = parseFloat(event.target.value.replace(/,/g, '')); // Bỏ dấu phẩy nếu có
      if (inputElement && (inputElement.tagName === "INPUT")) {
        if (this.obj_data.discount === '%' && value > 100) {
          inputElement.setCustomValidity(`The largest is 100%`);
          this.obj_data.discount_value = 100;
        } else {
          this.obj_data.discount_value = value;
          inputElement.setCustomValidity("");
        }
      }
    },
    async deleteData() {
      this.showDeleteDialog = false;
      var list_data = []
      for (let i = 0; i < this.listDataType.length; i++) {
        var item_data = this.listDataType[i];
        if (this.deleteId != item_data.id) {
          list_data.push(item_data);
        }
      }
      this.listDataType = list_data;
      this.loadTableType()
    },
    async loadTableType() {
      var stt = 0;
      var listDataType = this.listDataType;
      this.mapServicePrice = {};
      for (let i = 0; i < listDataType.length; i++) {
        stt++;
        var itemData = listDataType[i];
        var id = itemData?.id ?? null;
        listDataType[i].stt = stt;
        listDataType[i].index = i;
        this.mapServicePrice[id] = itemData;
      }
      this.listDataType = listDataType
      console.log(" this.listDataType:", this.listDataType)
      this.dataModelType = DataServices.getModel('service_property_type');
      this.dataModelType.processData(listDataType)
      this.componentKeyType++;
    },
    async save_item_list() {
      if (this.$commonFuction.isEmpty(this.obj_data.type_id)) {
        alert("Please select Service type of property!");
        return false;
      }
      var type_id = this.obj_data.type_id ?? null
      if (this.$commonFuction.isEmpty(this.obj_data.price_select)) {
        alert("Please enter price!");
        return false;
      }

      console.log("this.obj_data.type_id:", this.obj_data.type_id)
      console.log("this.obj_data.price_select:", this.obj_data.price_select)
      console.log("this.obj_data.mapPropertyType:", this.mapPropertyType)
      console.log("type_id:", type_id)
      var itemType = this.mapPropertyType[type_id]
      console.log("itemTypeitemType:", itemType)
      var itemObject = {
        // stt:1,
        id: itemType?.id ?? null,
        type_name: itemType?.name ?? null,
        price_key: this.obj_data.price_select,
      }
      if (!this.$commonFuction.isEmpty(this.index_edit)) {
        for (let i = 0; i < this.listDataType.length; i++) {
          var item_data = this.listDataType[i];
          var index = item_data.index
          if (type_id == item_data.id && index != this.index_edit) {
            alert("Service type of property already exists!");
            return false;
          }
        }
        this.listDataType[this.index_edit] = itemObject;
      } else {
        if (!this.$commonFuction.isEmpty(this.mapServicePrice[type_id])) {
          alert("Service type of property already exists!");
          return false;
        }
        this.listDataType.push(itemObject);
      }

      //var listDataType = this.listDataType;
      this.obj_data.type_id = null;
      this.obj_data.price_select = null;
      this.index_edit = null;
      this.loadTableType()
    },
    async onActionClicked(payload) {
      var action = payload.action
      var data = null
      if (payload.data) {
        data = payload.data
      }
      var pageTransaction = (new Date()).getTime();
      console.log("action.nameaction.name:", action.name)
      switch (action.name) {
        case 'view':
          this.dialogMode = action.name
          if (action.path !== undefined && action.path) {
            this.$router.push({ path: action.path + data.data[action.key] })
          } else {
            this.editId = data.data[action.key]
            this.displayDialog = true
            this.editDialogKey++
          }
          break
        case 'edit':
          console.log("datadatadata:", data)
          /*this.dialogMode = action.name
          if (action.path !== undefined && action.path) {
            console.log("payload.lazyParams:111"+pageTransaction,payload.lazyParams);
            localStorage.setItem(pageTransaction.toString(), JSON.stringify(payload.lazyParams));
            this.$router.push({ path: action.path + data.data[action.key],query: { page_transaction: pageTransaction.toString() }  })
          } else  {
            this.editId = data.data[action.key]
            this.displayDialog = true
            this.editDialogKey++
          }*/
          this.index_edit = data.data.index
          this.obj_data.type_id = data.data.id
          this.obj_data.price_select = data.data.price_key
          this.key_type_id++;
          break
        case 'delete':
          localStorage.setItem(pageTransaction.toString(), JSON.stringify(payload.lazyParams));
          this.page_transaction = pageTransaction;
          this.deleteId = data.data[action.key]
          console.log("this.deleteId:", this.deleteId)
          this.deleteName = data.data.name
          this.showDeleteDialog = true
          break
        case 'add':
          this.dialogMode = action.name
          if (action.path !== undefined && action.path) {
            this.$router.push({ path: action.path })
          } else {
            this.displayDialog = true
            this.editDialogKey++
          }
          break
        case 'custom':
          await action.handler(data.data, this)
          this.componentKey++
          break
      }
    },
    getRowActions() {
      var actions = {}
      var that = this;
      /*if (this.role.add) {
        actions.header = {name: 'add', icon: 'plus', path: `list-${this.modelName}/add`};
      }*/
      // actions.header = { name: 'add', icon: 'plus'};
      actions.rows = function (rowData) {
        var buttons = {}
        //buttons.view = { name: 'view', icon: 'eye', key: 'id', path: `list-${that.modelName}/view/`};
        //if (that.role.edit) {
        buttons.edit = { name: 'edit', icon: 'pencil', key: 'id', path: `list-${that.modelName}/edit/` };
        //}
        // buttons.edit = { name: 'edit', icon: 'pencil', key: 'id', path: `${that.modelName}/edit/`}
        //if (that.role.delete) {
        buttons.delete = { name: 'delete', icon: 'trash', key: 'id' }
        //}
        if (that.dataModelType.actions) {
          var modelaction = that.dataModelType.actions(rowData)
          Object.assign(buttons, modelaction);
        }
        return buttons
      }
      return actions
    },
    handleDataCkditor: function (e, index, key) {
      var dataContent = (e.length) ? e[0] : '';
      if (key == 'content') {
        //this.content = dataContent;
        this.listLang[index].content = dataContent;
      }
    }
    ,
    handlePlaceChanged(autocomplete) {
      console.log("autocomplete", autocomplete);
      if (autocomplete) {
        const lat = autocomplete.latitude;
        const long = autocomplete.longitude;

        // Lấy đầy đủ địa chỉ từ autocomplete
        const fullAddress = `${autocomplete.street_number} ${autocomplete.route}, ${autocomplete.administrative_area_level_2}, ${autocomplete.administrative_area_level_1}, ${autocomplete.country}`;

        this.obj_data.address = fullAddress;  // Gán đầy đủ địa chỉ vào obj_data.address
        this.obj_data.latitude = lat;
        this.obj_data.longtitude = long;
      }
    }


    ,
    async onFileSelectFile(event) {
      var that = this;
      let files = event.target.files || event.dataTransfer.files;

      // Kiểm tra số lượng ảnh đã chọn trước khi thêm
      if (this.list_file.length + files.length > 10) {
        alert("You can only upload a maximum of 10 images.");
        event.target.value = null; // Reset giá trị của input file
        return;
      }

      if (files.length > 0) {
        let acceptTags = ['jpeg', 'jpg', 'gif', 'png', 'JPEG', 'JPG', 'PNG', 'GIF', 'svg', 'SVG'];
        const maxFileSize = 2 * 1024 * 1024; // 2MB in bytes

        for (let i = 0; i < files.length; i++) {
          const fileExtension = files[i].name.split('.').pop();
          const fileSize = files[i].size;

          // Kiểm tra định dạng file
          if (!acceptTags.includes(fileExtension)) {
            alert("Only post formats are supported: jpeg, jpg, gif, png, svg");
            event.target.value = null;
            return;
          }

          // Kiểm tra kích thước file
          if (fileSize > maxFileSize) {
            alert("Each image must be smaller than 2MB.");
            event.target.value = null;
            return;
          }

          // Nếu file hợp lệ, thêm vào list
          that.obj_data.filename = files[i].name;
          this.comFilename++;
        }

        // Lưu trữ các tệp hợp lệ
        var arrTemp = [];
        for (let file of files) {
          file.source = file;
          file.objectURL = window.URL.createObjectURL(file);
          arrTemp.push(file);
        }

        // Cập nhật danh sách file
        this.list_file = this.list_file.concat(arrTemp);
      }

      // Reset giá trị của input file để nó sẵn sàng cho lần chọn tệp tiếp theo
      event.target.value = null;
    },


    isImage(file) {
      return /^image\//.test(file.type);
    },
    updateMatrix(data) {
      console.log(data);
      this.dataBookingRound = [...data];
      // this.componentKey++;
    },
    async toCalendar() {
      this.$router.push(`/admin/list-booking?view=calendar&propery_id=${this.selProperty?.id}`);
    },
    // getLisRecommendationCategory
    async getLisRecommendationCategory() {
      var where_key = {
        deleted: { _eq: false },
        // booking: {id: {_is_null: true} },
      }
      var list = await this.$CoreService.getLisRecommendationCategory(where_key);
      this.listPropertyType = list.list;
      this.listPropertyTypeAll = list.list;
      this.mapPropertyType = list.map_data;
    },

    async SearchPropertyType(search, loading) {
      console.log(loading);
      if (search !== '' && search !== null) {
        var where_key = {
          deleted: { _eq: false },
        };
        if (!this.$commonFuction.isEmpty(search)) {
          where_key.name = {
            _iregex: this.$commonFuction.search(search),
          };
        }
        var list = await this.$CoreService.getLisRecommendationCategory(where_key);
        this.listPropertyType = list.list;
        //console.log("this.listProperty:",this.listProperty)
      } else {
        // cosnole.log("dasdasd")
        this.listPropertyType = this.listPropertyTypeAll
      }
    },
    async getListCountry() {
      var where_key = {
        deleted: { _eq: false },
        // booking: {id: {_is_null: true} },
      }
      var list = await this.$CoreService.getListCountry(where_key);
      this.listCountry = list.list;
      this.listCountryAll = list.list;
    },
    async SearchCountry(search, loading) {
      console.log(loading);
      if (search !== '' && search !== null) {
        var where_key = {
          deleted: { _eq: false },
        };
        if (!this.$commonFuction.isEmpty(search)) {
          where_key.name = {
            _iregex: this.$commonFuction.search(search),
          };
        }
        var list = await this.$CoreService.getListCountry(where_key);
        this.listCountry = list.list;
        //console.log("this.listProperty:",this.listProperty)
      } else {
        // cosnole.log("dasdasd")
        this.listCountry = this.listCountryAll
      }
    },
    async getListState() {
      var where_key = {
        deleted: { _eq: false },
        // booking: {id: {_is_null: true} },
      }
      var list = await this.$CoreService.getListState(where_key);
      this.listState = list.list;
      this.listStateAll = list.list;
    },
    async SearchState(search, loading) {
      console.log(loading);
      if (search !== '' && search !== null) {
        var where_key = {
          deleted: { _eq: false },
        };
        if (!this.$commonFuction.isEmpty(search)) {
          where_key.name = {
            _iregex: this.$commonFuction.search(search),
          };
        }
        var list = await this.$CoreService.getListState(where_key);
        this.listState = list.list;
        //console.log("this.listProperty:",this.listProperty)
      } else {
        // cosnole.log("dasdasd")
        this.listState = this.listStateAll
      }
    },
    async getListUser() {
      var where_key = {
        deleted: { _eq: false },
        role: { _eq: this.$constants.ROLE_ACCOUNT.OWNER },
        // booking: {id: {_is_null: true} },
      }
      var list = await this.$CoreService.getListUser(where_key);
      this.listUser = list.list;
      this.listUserAll = list.list;
    },
    async SearchUser(search, loading) {
      console.log(loading);
      if (search !== '' && search !== null) {
        var where_key = {
          deleted: { _eq: false },
        };
        if (!this.$commonFuction.isEmpty(search)) {
          where_key.name = {
            _iregex: this.$commonFuction.search(search),
          };
        }
        var list = await this.$CoreService.getListUser(where_key);
        this.listUser = list.list;
        //console.log("this.listProperty:",this.listProperty)
      } else {
        // cosnole.log("dasdasd")
        this.listUser = this.listUserAll
      }
    },

    async getListDocsCate() {
      var where_key = {
        deleted: { _eq: false },
        // booking: {id: {_is_null: true} },
      }
      var list = await this.$CoreService.getListDocsCate(where_key);
      this.listDocsCate = list.list;
      this.listDocsCateAll = list.list;
    },

    async SearchDocsCate(search, loading) {
      console.log(loading);
      if (search !== '' && search !== null) {
        var where_key = {
          deleted: { _eq: false },
        };
        if (!this.$commonFuction.isEmpty(search)) {
          where_key.name = {
            _iregex: this.$commonFuction.search(search),
          };
        }
        var list = await this.$CoreService.getListDocsCate(where_key);
        this.listDocsCate = list.list;
        //console.log("this.listProperty:",this.listProperty)
      } else {
        // cosnole.log("dasdasd")
        this.listDocsCate = this.listDocsCateAll
      }
    },

    backToList() {
      this.$emit('back');
    },
    async validateData() {
      // Gọi kiểm tra ngày

      this.validationErrors = {};

      // Giới hạn độ dài của trường name
      const maxNameLength = 100;

      // Lấy giá trị discount_value từ trường number_percent
      const discountValue = parseFloat(this.obj_data.discount_value);

      // Các trường cần kiểm tra
      const fieldsToValidate = [
        { id: "name", value: this.obj_data.name },
        { id: "type_id", value: this.obj_data.category_id },
        { id: "address", value: this.obj_data.address },
        { id: "latitude", value: this.obj_data.latitude },
        { id: "longtitude", value: this.obj_data.longtitude },
        { id: "number_percent", value: this.obj_data.discount_value },  // Trường discount_value
      ];

      let isFormInvalid = false;

      // Kiểm tra từng trường trong fieldsToValidate
      for (let i = 0; i < fieldsToValidate.length; i++) {
        let field = fieldsToValidate[i];
        let inputElement = document.getElementById(field.id);

        if (inputElement && (inputElement.tagName === "INPUT" || inputElement.tagName === "TEXTAREA" || inputElement.tagName === "SELECT")) {
          // Kiểm tra rỗng cho các trường khác, nhưng không kiểm tra rỗng cho number_percent
          if (field.id !== "number_percent" && this.$commonFuction.isEmptyObject(field.value)) {
            inputElement.setCustomValidity(this.$constants.MSG_TEXT.INPUT_INVALID_MSG);
            isFormInvalid = true;
          }
          // Kiểm tra độ dài của trường name
          else if (field.id === "name" && field.value.length > maxNameLength) {
            inputElement.setCustomValidity(`${maxNameLength} character limit`);
            isFormInvalid = true;
          }
          // Kiểm tra discount_value khi người dùng đã nhập và đơn vị là %
          else if (field.id === "number_percent" && discountValue && this.obj_data.discount === '%' && discountValue >= 100) {
            inputElement.setCustomValidity(`Discount must be less than 100%`);
            inputElement.reportValidity();  // Hiển thị thông báo lỗi ngay lập tức
            isFormInvalid = true;
          }
          // Xóa thông báo lỗi nếu hợp lệ
          else {
            inputElement.setCustomValidity("");
          }
        }
      }

      // Kiểm tra Description cho từng ngôn ngữ trong listLang
      for (let i = 0; i < this.listLang.length; i++) {
        let item = this.listLang[i];
        let contentElement = document.getElementById("validator_content_" + item.iso_code);

        if (contentElement) {
          if (this.$commonFuction.isEmptyObject(item.content)) {
            contentElement.setCustomValidity(this.$constants.MSG_TEXT.INPUT_INVALID_MSG);
            contentElement.reportValidity();
            isFormInvalid = true;
          } else {
            contentElement.setCustomValidity("");
          }
        }
      }

      // Kiểm tra trường Images
      let list_file = document.getElementById("list_file");
      if (this.list_file.length === 0) {
        list_file.setCustomValidity(this.$constants.MSG_TEXT.FILE_INVALID_MSG);
        isFormInvalid = true;
      } else {
        list_file.setCustomValidity("");
      }

      // Kiểm tra tính hợp lệ của toàn bộ form
      let inpObj = document.getElementById("frm_action");
      if (!inpObj.checkValidity()) {
        inpObj.reportValidity();
        isFormInvalid = true;
      }

      return isFormInvalid; // Trả về true nếu form không hợp lệ
    }



    ,

    validateDates() {
      const fromDate = new Date(this.obj_data.discount_from);
      const toDate = new Date(this.obj_data.discount_to);

      console.log("datalaaa", fromDate, toDate);

      if (isNaN(fromDate.getTime()) || isNaN(toDate.getTime())) {
        console.log("One or both dates are invalid.");
        return; // Không thực hiện gì nếu ngày không hợp lệ
      }

      if (toDate < fromDate) {
        alert('The end date cannot be earlier than the start date.');
        this.obj_data.discount_to = '';
        document.getElementById('end_booking_date').setCustomValidity('Ngày "To" không thể nhỏ hơn ngày "From".');
      } else {
        document.getElementById('end_booking_date').setCustomValidity('');
      }
    },
    async checkCode(code) {
      // if(this.email !== null && this.email !== ''){
      var where = {
        code: { '_eq': code },
        deleted: { '_eq': false },
        // type: {'_eq' : 'user'}
      }
      if (this.model !== undefined) where = Object.assign(where, { id: { '_neq': this.model.id } })
      var { data } = await this.$apollo.query({
        query: DataServices.getList('property', { "fields": "id, name,code" }),
        variables: {
          where_key: where
        },
        fetchPolicy: "network-only"
      })
      // console.log(data)
      return data && data[Object.keys(data)].length > 0
      //}
    },
    async saveData() {
      this.$commonFuction.is_loading(true);
      if (await this.validateData()) {
        this.$commonFuction.is_loading(false);
        return
      }


      var params = this.obj_data


      params["list_file"] = this.list_file
      params["list_lang"] = this.listLang
      params["list_id_file_delete"] = this.list_id_file_delete
      console.log('saveDatasaveData', this.list_file)
      this.$emit('save', params)
    },
    onFileClear(item, index) {
      console.log('itemitem:', item)
      var type = item?.type ?? null
      if (type == 'old_edit') {
        this.list_id_file_delete.push(item);
      }
      this.list_file.splice(index, 1);
    },
  },
  // watch: {
  //   'obj_data.discount_from': function() {
  //     if (this.obj_data.discount_from && this.obj_data.discount_to) {
  //       this.validateDates();
  //     }
  //   },
  //   'obj_data.discount_to': function() {
  //     if (this.obj_data.discount_from && this.obj_data.discount_to) {
  //       this.validateDates();
  //     }
  //   }
  // },


}
</script>

<style lang="scss">
.p-formgrid {
padding-top: 20px;
}
.main-style-button {
  background-color: #0086C4 !important;
}

.custom-select {
  width: 100%;
  padding-right: 30px;
  /* Tăng khoảng cách bên phải để không che khuất mũi tên */
  font-size: 16px;
  border: 1px solid #ced4da;
  border-radius: 4px;
  background: url("data:image/svg+xml,%3Csvg width='14' height='14' xmlns='http://www.w3.org/2000/svg' fill='%23222'%3E%3Cpath d='M7 10L3 6h8L7 10z'/%3E%3C/svg%3E") no-repeat right 10px center;
  background-color: #fff;
  background-size: 12px;
  appearance: none;
  /* Có thể giữ lại hoặc loại bỏ */
}

.custom-select:focus {
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.opacity-text-file {
  opacity: 0.5;
}

.calendar-css-margin1 {
  padding-right: 0px !important;
}

.calendar-css-margin2 {
  padding-left: 0px !important;
}

.p-multiselect {
  width: 450px;
}

.p-multiselect-label:not(.p-placeholder) {
  padding-top: .25rem;
  padding-bottom: .25rem;

}

.category-item-value {
  padding: .25rem .5rem;
  border-radius: 3px;
  display: inline-flex;
  margin-right: .2rem;
  background-color: var(--primary-color);
  color: var(--primary-color-text);
}

.p-multiselect-label-container {
  height: 40px;
}

.p-inputtext {
  margin: 0px 0px 0px 4px;
}

//style form add-edit
@media screen and (min-width: 1001px) {
  .input_distance {
    width: 728px !important;
  }
}

label.p-col-fixed txt-right {
  margin-bottom: 0px !important;
}

div.p-card-field {
  padding: 0px !important;
}

.p-col-fixed {
  padding: 0px !important;
  margin: 0px 0px 0px 9px !important;
}

.input_text {
  width: 760px !important;
  height: 48px !important;
}

.txt-right {
  font-weight: bold;
}

.multiselect {
  width: 760px !important;
}

.layout-main {
  background: #00000029;
}

.vs__clear {
  display: none;
}

.input_width {
  width: 760px !important;
  height: 48px !important;
}

#distance {
  width: 1px !important;
  height: 48px !important;
  border-left: 0px;
  border-top: 0px;
  border: 1px solid #C8C8C8;
  border-radius: 0px;
  //border-top: none!important;
  //border: 1px solid rgba(60, 60, 60, 0.26);;
}

.manager-title {
  font-size: 24px !important;
}

.input-dif {
  margin-left: 5px !important;
  border-top: 0px;
}

.p-inputgroup-addon {
  height: 48px;
}

.txt-right {
  font-weight: bold;
}

.p-col-fixed {
  padding: 0px !important;
  //margin:0px 0px 0px 10px !important;
}

label.p-col-fixed txt-right {
  margin-bottom: 0px !important;
}

div.p-card-field {
  padding: 0px !important;
}

.margin {
  margin-bottom: 12px !important;
}

.border_color {
  border: 1px solid #C8C8C8;
}

.text {
  font-weight: normal !important;
  margin-left: 15px !important;
}

.div_check {
  margin-top: 12px !important;
}


//responsive
@media screen and (max-width: 600px) {
  .input_width {
    width: 100% !important;
  }

  .div-button {
    padding: 8px !important;
  }

  #distance {
    text-align: left !important;
    padding: 6.8px;
    width: 100% !important;
  }

  .p-inputgroup-addon {
    margin-right: 12px;
  }

}

@media screen and (max-width: 1000px) {
  .input_width {
    width: 100% !important;
  }

  .div-button {
    padding: 8px !important;
  }

  .p-inputgroup-addon {
    margin-right: 12px;
  }

  #distance {
    text-align: left !important;
    padding: 6.8px;
  }
}

.p-button-outlined {
  .p-button-label {
    color: #007ad9 !important;
  }
}

.cus-text {
  margin-left: 2px;
  margin-top: 4px;
}

.style-box-phone {
  border: 1px solid #a6a6a6;
  width: 100%;
  display: flex;

  .p-dropdown {
    width: 100px;
    border: 0px;
    box-shadow: none !important;
  }

  .p-focus {
    border: 0px;
    box-shadow: none;
  }

  .p-dropdown:focus {
    border: 0px;
    box-shadow: none;
  }

  input {
    width: calc(100% - 100px);
    margin-left: 0px;
    border: 0px;
  }

  .p-inputtext:enabled:focus {
    border: 0px;
    box-shadow: none;
  }
}

.style_list_image {
  display: flex;
  flex-wrap: wrap;
  /* Cho phép các phần tử xuống dòng */
  gap: 10px;

  /* Khoảng cách giữa các phần tử */
  .box-image {
    width: 100px;
    height: 100px;
    margin-top: 8px;
    margin-bottom: 8px;
    position: relative;

    .style_img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border: 1px solid #D0D4D9;
      border-radius: 10px;
    }

    .button-clear {
      width: 20px;
      height: 20px;
      position: absolute;
      top: 1px;
      right: 8px;
    }
  }
}

.style_box_room {
  display: flex;
}

.style_box_lat_log {
  display: flex;

  .box_lat_log {
    width: 50%;
  }
}

.style_title_group {
  font: normal normal bold 20px/27px Segoe UI;
  letter-spacing: 0px;
  color: #666666;
  text-transform: uppercase;
}

.style_box_price {
  padding-left: 35px !important;
}

.style_rad_button {
  label {
    margin-bottom: 0px;
    margin-left: 15px;
  }
}

.style_btn_add_type {
  width: 60px;
}
</style>
